<template>
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="100px" style="display: flex;" :rules="rules">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24">
          <el-col :span="8">
            <el-form-item label="供应商" :class="$i18n.locale">
              <el-select v-model="form.vendorId" value-key="id" multiple clearable filterable>
                <el-option
                  v-for="item in vendorOptions"
                  :key="item.id"
                  :label="item.vendorName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="币种" prop="currencyType">
              <el-select v-model="form.currencyType" :placeholder="$t('page.selectPlaceholder')">
                <!-- <el-option label="美元" value="1" />
                <el-option label="人民币" value="2" /> -->
                <el-option v-for="item in _getAllCommodityDict('CURRENCY_TYPE')" :key="item.val" :label="item.label" :value="item.val" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="逾期时间" :class="$i18n.locale" prop="overdueTime">
              <el-date-picker
                v-model="form.overdueTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :picker-options="pickerOptions"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :span="24" class="mb-3">
          <el-col :span="24">
            <ImportButton export-key="" :params="form" />
            <el-button type="primary" class="ml-3" @click="dialogVisible=true">设置汇率</el-button>
          </el-col>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" :loading="loading" @click="_unclearedOrderPage()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- 设置汇率的弹框 -->
    <el-dialog
      title="设置汇率"
      :visible.sync="dialogVisible"
      width="30%"
    >
      <el-form :model="editForm" inline>
        <el-form-item label="汇率" label-width="100">
          <el-input v-model="editForm.rate" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="rateSure">确 定</el-button>
      </span>
    </el-dialog>
    <vxe-grid v-bind="gridOptions" />
  </div>
</template>

<script>
import XEUtils from 'xe-utils'
import ImportButton from '@/components/ImportButton'
import { queryVendorList } from '@/api/scm-api'
import { payableAccountAge } from '@/api/deposit-api'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'

import dayjs from 'dayjs'
export default {
  components: { ImportButton },
  mixins: [commodityInfoDict],

  data() {
    return {
      editForm: {},
      form: {
        overdueTime: dayjs().format('YYYY-MM-DD')
      },
      rules: { overdueTime: { required: true, message: '必填', trigger: 'change' },
        currencyType: { required: true, message: '必填', trigger: 'change' }},
      vendorOptions: [],
      dialogVisible: false,
      loading: false,
      gridOptions: {
        loading: false,
        width: '100%',
        align: 'center',
        border: true,
        resizable: true,
        maxHeight: 500,
        highlightHoverRow: true,
        showFooter: true,
        showOverflow: true,
        footerMethod: this.footerMethod,
        columns: [
          { type: 'seq', width: 60 },
          { field: 'vendorName', title: '供应商' },
          { field: 'currency', title: '币种' },
          { field: 'notCollectNotPayPrice', title: '未交货未付', sortable: true, width: 120 },
          { field: 'deposit', title: '定金', sortable: true, width: 100 },
          { field: 'collectNotPayPrice', title: '已交货未付汇总', width: 150, sortable: true },
          {
            title: '已交货未付逾期明细（天）',
            children: [
              { field: 'notOverdue', title: '未逾期', sortable: true },
              { field: 'overdue1', title: '0-30', sortable: true },
              { field: 'overdue2', title: '31-60', sortable: true },
              { field: 'overdue3', title: '61-90', sortable: true },
              { field: 'overdue4', title: '91-120', sortable: true },
              { field: 'overdue5', title: '121-150', sortable: true },
              { field: 'overdue6', title: '151-180', sortable: true },
              { field: 'overdue7', title: '180+', sortable: true }
            ]
          }
        ],
        data: []
      },
      TableLoading: false,
      showRow: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24
        }
      }
    }
  },
  computed: {
    _unclearedOrderParams() {
      const { overdueTime, currencyType, vendorId } = this.form
      const { rate } = this.editForm
      return Object.assign({}, { rate, overdueTime, currencyType, vendorId: vendorId ? vendorId.toString() : '' })
    }
  },
  watch: {
    'dialogVisible'(val) {
      if (!val) {
        this.editForm = {}
      }
    }
  },
  created() {

  },
  mounted() {
    this._queryVendorList()
    // this._unclearedOrderPage()
  },
  methods: {
    rateSure() {
      this._unclearedOrderPage()
      this.dialogVisible = false
    },
    footerMethod({ columns, data }) {
      const a = columns.map((column, columnIndex) => {
        if (columnIndex === 0) {
          return '合计'
        }
        if (!['vendorName', 'currency'].includes(column.property)) {
          if ((XEUtils.sum(data, column.property) + '').indexOf('.') !== -1) {
            return (XEUtils.sum(data, column.property)).toFixed(2)
          } else {
            return XEUtils.sum(data, column.property)
          }
        }
        return ' '
      })
      const b = columns.map((column, columnIndex) => {
        if (columnIndex === 0) {
          return '比例'
        }
        if (!['vendorName', 'currency', 'notCollectNotPayPrice', 'collectNotPayPrice', 'deposit'].includes(column.property)) {
          const a = Number(XEUtils.sum(data, column.property) / XEUtils.sum(data, 'collectNotPayPrice') * 100)
          if (isNaN(a)) { return '' } else if ((a + '').indexOf('.') !== -1) { return a.toFixed(2) + '%' } else { return a + '%' }
        }
        return ' '
      })
      return [a, b]
    },
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    async _unclearedOrderPage() {
      this.$refs['form'].validate(async(valid) => {
        if (valid) {
          try {
            this.gridOptions.loading = true
            this.loading = true
            const { datas } = await payableAccountAge(this._unclearedOrderParams)
            this.gridOptions.data = datas
          } catch (err) {
            console.log(err)
          } finally {
            this.gridOptions.loading = false
            this.loading = false
          }
        } else {
          return false
        }
      })
    },
    resetClick() {
      this.form = {}
      // this.form.forecastTime = dayjs().format('YYYY-MM')
      // this._unclearedOrderPage()
    }
  }
}
</script>

<style scoped lang="scss">
.el-table--border th.gutter:last-of-type {
    display: block!important;
    width: 17px!important;
}

/deep/.el-table th.gutter{
    display: table-cell!important;}
</style>
